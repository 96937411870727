import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/render/project/src/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground } from 'docz';
import { H1, H2, H3, H4, H5, H6, Subtitle1, Subtitle2, Body1, Body2 } from './typography';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "typography"
    }}>{`Typography`}</h1>
    <blockquote>
      <p parentName="blockquote">{`Considered use of typography presents content clearly and efficiently for every user.`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`This component is currently up for consideration to best align with Kuali's `}<a parentName="p" {...{
          "href": "/rudiments/typography"
        }}>{`Typography Rudiments`}</a>{`.`}</p>
    </blockquote>
    <h2 {...{
      "id": "usage"
    }}>{`Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { H1, H2, ...rest } from '../../ui/typography'
`}</code></pre>
    <h2 {...{
      "id": "font-family"
    }}>{`Font Family`}</h2>
    <p>{`We use Roboto. It's set as the font family for everything in kui. You can include it in your project, along with material icons, by inserting this snippet in the head of your index.html:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<link
  href="https://fonts.googleapis.com/css?family=Roboto:400,500%7CMaterial+Icons"
  rel="stylesheet"
/>
`}</code></pre>
    <p>{`This will include regular and medium font weights. If you need more weights, add them next to `}<inlineCode parentName="p">{`400,500`}</inlineCode>{` in the snippet above.`}</p>
    <h2 {...{
      "id": "type-scale"
    }}>{`Type Scale`}</h2>
    <Playground __position={0} __code={'<H1>H1</H1>\n<H2>H2</H2>\n<H3>H3</H3>\n<H4>H4</H4>\n<H5>H5</H5>\n<H6>H6</H6>\n<Subtitle1>Subtitle 1</Subtitle1>\n<Subtitle2>Subtitle 2</Subtitle2>\n<Body1>Body 1</Body1>\n<Body2>Body 2, Caption</Body2>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      H1,
      H2,
      H3,
      H4,
      H5,
      H6,
      Subtitle1,
      Subtitle2,
      Body1,
      Body2,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <H1 mdxType="H1">H1</H1>
  <H2 mdxType="H2">H2</H2>
  <H3 mdxType="H3">H3</H3>
  <H4 mdxType="H4">H4</H4>
  <H5 mdxType="H5">H5</H5>
  <H6 mdxType="H6">H6</H6>
  <Subtitle1 mdxType="Subtitle1">Subtitle 1</Subtitle1>
  <Subtitle2 mdxType="Subtitle2">Subtitle 2</Subtitle2>
  <Body1 mdxType="Body1">Body 1</Body1>
  <Body2 mdxType="Body2">Body 2, Caption</Body2>
    </Playground>
    <h3 {...{
      "id": "guidelines"
    }}>{`Guidelines`}</h3>
    <p>{`This type scale is intended to serve as a starting point for the typography in our applications, it is not intended to be exhaustive and we expect each team to define neccessary styles outside of this scale for their applications.`}</p>
    <p>{`The use of the specified Body 1 style for the base font in all applications is key to the compatability of components working throughout the Kuali ecosystem of apps.`}</p>
    <p>{`While our type scale only specifies the Regular and Medium styles to simplify, drive legibility and reduce font loading to Regular, Regular Italic, Medium and Medium Italic there may be situations where use of the other styles is appropriate.`}</p>
    <h2 {...{
      "id": "padding-and-margin"
    }}>{`Padding and Margin`}</h2>
    <p>{`Because adjusting padding and margin is so common and particular to each use case, we've provided shorthand props of `}<inlineCode parentName="p">{`p`}</inlineCode>{` and `}<inlineCode parentName="p">{`m`}</inlineCode>{`. You may still set padding and margin as specified in the next section if you prefer.`}</p>
    <Playground __position={1} __code={'<H1 m=\"20px 0\">H1 Text</H1>\n<Subtitle1 p=\"50px\">Subtitle1 Text</Subtitle1>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      H1,
      H2,
      H3,
      H4,
      H5,
      H6,
      Subtitle1,
      Subtitle2,
      Body1,
      Body2,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <H1 m='20px 0' mdxType="H1">H1 Text</H1>
  <Subtitle1 p='50px' mdxType="Subtitle1">Subtitle1 Text</Subtitle1>
    </Playground>
    <h2 {...{
      "id": "overriding-styles--additional-props"
    }}>{`Overriding Styles + Additional Props`}</h2>
    <p>{`Each of these components is a styled-component underneath. This means that any props you pass in will be forwarded to the underlying DOM element. You can override styles either by using the `}<inlineCode parentName="p">{`style`}</inlineCode>{` prop, or wrapping it with `}<inlineCode parentName="p">{`styled`}</inlineCode>{` from styled-components. You may also override which DOM element we render (for accessibility and semantics) by using the `}<inlineCode parentName="p">{`as`}</inlineCode>{` prop.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<H1 style={{ color: '#186704' }}>H1 Text</H1>

<H1 className='m-20'>H1 Text</H1>

import styled from 'styled-components'
const MyH1 = styled(H1)\`
  padding: 50px;
\`

<H6 as="p">I'm actually a p tag</H6>
`}</code></pre>
    <h2 {...{
      "id": "testing"
    }}>{`Testing`}</h2>
    <p>{`Because each of these component outputs a single element to the DOM, you can treat this component as if it were just a native DOM elemnt when running tests.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      